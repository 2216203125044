$col-padding: 2.15rem;

.verification {
  display: flex;
  margin-left: 0;
  overflow: hidden;
  width: 100%;

  &--loading {
    display: block;
    padding-top: 5.72rem;
  }

  &__col {
    height: 100%;
    overflow: hidden;
    padding-bottom: 4.57rem;

    .col {
      &__inner {
        box-sizing: content-box;
        height: 100%;
        overflow-y: auto;
        padding-bottom: $col-padding;
        padding-top: $col-padding;
        width: 100%;
      }
    }

    &--hidden-scrollbar {
      .col {
        &__inner {
          padding-right: $col-padding;
        }
      }
    }
  }

  &__note {
    margin-bottom: 2.5rem;
  }

  &__title {
    margin: 0 2rem 1rem 0;
  }
}
