@import '../../../../../styles/variables';
@import '../../../../../styles/bootstrap';

.notifications-content {
  box-shadow: 0 -5px 18px 0 $grey-shadow;
  max-width: 30.86rem;
  min-height: 10rem;

  @include media-breakpoint-up(sm) {
    width: 30.86rem;
  }

  &__tabs {
    .tabs {
      &__content {
        box-shadow: 0 5px 18px $grey-shadow;
        padding: 0 1.4rem;
        position: relative;
        z-index: 5;
      }

      &__button {
        margin-bottom: 0.2rem;
      }

      &__panel {
        margin-top: 0;
      }
    }
  }
}
