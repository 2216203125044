@import './src/styles/variables';
@import './src/styles/bootstrap';

.landing-page {
  &__container {
    align-items: center;
    display: flex;
    flex: auto;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin: auto;
    padding: 7rem 10rem;

    @include media-breakpoint-up(sm) {
      height: 100vh;
      padding: 1rem;
    }

    @include media-breakpoint-down(md) {
      padding: 2rem;
    }

    .container {
      &__column {
        margin-bottom: 7.5rem;
        min-height: 80vh;
        width: 100vw;

        @include media-breakpoint-up(sm) {
          width: auto;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 2.5rem;
        }

        @include media-breakpoint-up(xl) {
          min-height: 57.5vh;
        }

        .column {
          &__navigation {
            min-height: 3rem;
          }
        }
      }
    }
  }

  &__brand {
    background-color: $brand-primary;
    display: flex;
    flex: auto;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    .brand {
      &__logo-container {
        align-items: flex-end;
        background-color: $brand-logo-background;
        display: flex;
        height: 15rem;
        justify-content: center;
        padding-bottom: 2rem;
        width: 11rem;
      }
    }
  }
}
