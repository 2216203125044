@import '../../../../../../../../../../../../styles/variables';

.match-section {
  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  &--form:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__title-container {
    align-items: center;
    display: flex;
    margin-bottom: 1.15rem;

    .title-container {
      &__title {
        margin: 0 2rem 0 1.57rem;
      }

      &__actions {
        background-color: $white;
        border: 1px solid $grey-200;
        border-radius: 4px;
        box-shadow: 0 2px 16px 0 $grey-100;
        margin-left: 2.5rem;
        padding: 0.5rem;

        .actions {
          &__button {
            margin-left: 0.35rem;
            margin-right: 0.35rem;
            padding: 0.7rem;

            &:last-child {
              margin-right: 0.35rem;
            }
          }
        }
      }
    }
  }

  &__table-container {
    overflow: hidden;
  }

  &__table {
    .table {
      &__cell {
        &.MuiTableCell {
          &-root {
            border: 0;
            font-size: 1rem;
            padding: 0.57rem 1rem;
            vertical-align: top;

            &:first-child {
              padding-left: 0;
            }
          }
        }

        &--color-indicator {
          max-width: 0.57rem;
          width: 0.57rem;

          &.MuiTableCell {
            &-root {
              padding: 0.57rem 0;

              .wrapper {
                border-radius: 4px;
                height: 3.15rem;
                width: 0.57rem;
              }
            }
          }
        }

        &--value {
          padding-bottom: 0.8rem;
          padding-top: 0.8rem;

          input,
          textarea {
            color: $black;
            -webkit-text-fill-color: $black;
          }
        }
      }
    }
  }

  &--error {
    .match-section {
      &__title-container {
        .title-container {
          &__title {
            color: $error;
          }
        }
      }
    }
  }
}
