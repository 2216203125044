@import './../../../../styles/variables';

.data-hub-select {
  &__label {
    color: $black;
    margin-bottom: 0.9rem;

    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.35rem 0 0 0.35rem;
      }
    }
  }

  &__input {
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }

    .MuiSelect {
      &-select {
        background-color: $grey-100;
        border: 1px solid $grey-100;
        border-radius: 4px;
        opacity: 1;
        padding: 0.9rem;

        &:hover {
          border-color: $brand-secondary;
        }
      }

      input {
        padding: 0;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    &.Mui-error {
      .MuiSelect {
        &-select {
          border-color: $error;

          &:hover {
            border-color: $error;
          }
        }
      }
    }

    &.Mui-focused:not(&.Mui-error) {
      .MuiSelect-select {
        background-color: $white;
        border-color: $brand-secondary;
      }
    }
  }

  &--grid {
    .MuiGrid {
      &-root {
        align-items: center;
        display: flex;
      }
    }

    .data-hub-select {
      &__label {
        margin-bottom: 0;
      }
    }
  }
}
