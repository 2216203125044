@import './../../../../styles/variables';

.data-hub-card {
  max-width: 25rem;
  text-align: left;

  &__title {
    font-size: 1.6rem;
  }

  &__content {
    color: $grey-400;
    font-size: 0.9rem;
    margin-bottom: 0;
    padding: 1rem;
  }

  &__actions {
    padding: 0.2rem 1rem 1rem;
  }
}
