@import './../../../../../../styles/variables';
@import './../../../../../../styles/bootstrap';

.invoice-history {
  width: 100%;

  &__entry {
    .entry {
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 1.5rem;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }

        .container {
          &__info {
            width: 11.5rem;
          }

          &__person {
            font-weight: 500;
            margin-bottom: 0.5rem;
            overflow-wrap: break-word;
          }

          &__date {
            font-size: 0.86rem;
            margin-bottom: 0;
          }

          &__value {
            margin-top: 1rem;

            @include media-breakpoint-up(sm) {
              margin-left: 1rem;
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &__button {
    text-align: center;
    white-space: nowrap;
    width: 100%;

    &::before,
    &::after {
      border-top: 1px solid $grey-100;
      content: '';
      display: inline-block;
      margin: 0;
      transform: translateY(-0.25rem);
      width: 25%;
    }
  }
}
