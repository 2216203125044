@import '../../../styles/variables';

@mixin cube-rotate-animation($rotation-level) {
  $deb: 90deg;
  $delay: 0.3s;

  transform: scale(1.1) rotateZ($deb * $rotation-level);

  &::before {
    animation-delay: $delay * $rotation-level;
  }
}

.loading {
  background-color: $resident-dark;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;

  &--overlay {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  &--relative,
  &--contained {
    align-content: center;
    background-color: transparent;
    height: auto;
    width: 100%;
  }

  &--relative {
    position: relative;
  }

  &__container {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    .container {
      &__loader {
        height: 40px;
        margin: 0 auto;
        position: relative;
        transform: rotateZ(45deg);
        width: 40px;

        &--sm {
          height: 25px;
          width: 25px;
        }

        .loader {
          &__cube {
            float: left;
            height: 50%;
            position: relative;
            transform: scale(1.1);
            width: 50%;

            &::before {
              animation: fold-cube-angle 2.4s infinite linear both;
              background-color: #ecbf77;
              content: '';
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              transform-origin: 100% 100%;
              width: 100%;
            }

            &--2 {
              @include cube-rotate-animation(1);
            }

            &--3 {
              @include cube-rotate-animation(3);
            }

            &--4 {
              @include cube-rotate-animation(2);
            }
          }
        }
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fold-cube-angle {
  0%,
  10% {
    opacity: 0;
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  100% {
    opacity: 0;
    transform: perspective(140px) rotateY(180deg);
  }
}
