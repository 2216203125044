@import '../../../../../../../../styles/variables';

$more-details-spacer: 2rem;

.invoice-details {
  display: flex;
  min-height: 6rem;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__table {
    .table {
      &__cell {
        border: 0;
        font-size: 1rem;
        padding-top: 0;
        vertical-align: top;

        p {
          margin-bottom: 0;
          white-space: pre-wrap;
          word-wrap: break-word;
        }

        &--header {
          padding-bottom: 0.2rem;
          padding-top: 1.14rem;
        }
      }
    }
  }

  &--disabled {
    .table {
      &__cell {
        color: $grey-500;
      }
    }
  }

  &__more {
    display: flex;
    justify-content: space-between;

    &--with-note {
      margin-top: $more-details-spacer;
    }

    .more {
      &__note {
        width: 60%;
      }

      &__buttons {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        margin-top: $more-details-spacer;
        width: 100%;

        > * {
          margin-left: 3rem;
        }
      }
    }
  }
}
