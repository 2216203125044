.invoices-report-form {
  text-align: center;

  &__container {
    display: flex;
    justify-content: center;

    .container {
      &__separator {
        position: relative;
        right: 0.36rem;
        top: 3rem;
      }
    }
  }
}
