@import '../../../../../../styles/variables';

.container {
  .root {
    align-items: center;
    background-color: $grey-100;
    border: 1px solid $brand-primary;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 8.9rem;
    justify-content: space-evenly;
    padding-top: 1.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .subtitle {
      font-weight: 500;
      margin-bottom: 0;
    }

    &:hover {
      background-color: $white;
      box-shadow: inset 0 0 24px #b9680040, 0 0 8px #f2aa1e80;
    }
  }
}
