@import '../../../styles/bootstrap';
@import '../../../styles/variables';

@mixin status-color-scheme($color) {
  .table {
    &__cell {
      &.MuiTableCell {
        &-root {
          &:first-child::before {
            background-color: $color;
            border-color: $color;
            margin-right: 1.5rem;
          }
        }
      }

      &--status {
        &.MuiTableCell {
          &-root {
            color: $color;
          }
        }
      }
    }
  }
}

.table {
  .MuiTableContainer {
    &-root {
      border-radius: 10px 10px 0 0;
      position: relative;
    }
  }

  &__toolbar {
    margin-bottom: 2.3rem;

    .toolbar {
      &__actions {
        align-items: center;
        display: flex;
        justify-content: flex-start;

        @include media-breakpoint-up(lg) {
          &--with-search {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__top-content {
    margin-bottom: 2.3rem;
  }

  &__cell {
    &--loading-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 0.86rem;
    }
  }

  &__row {
    transition:
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.MuiTableRow {
      &-root {
        &:hover {
          background-color: transparent;
          cursor: auto;
        }
      }
    }

    &--clickable {
      &.MuiTableRow {
        &-root {
          &:hover {
            background-color: $grey-100;
            cursor: pointer;
          }
        }
      }
    }

    &--details {
      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              border: 0;
              font-size: 1rem;
              padding: 0;
            }
          }
        }
      }
    }

    &--expanded-details {
      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              border: 1px solid $brand-secondary;
              box-shadow:
                inset 16px 0 16px -16px $brand-secondary,
                inset -16px 0 16px -16px $brand-secondary,
                inset 0 -16px 16px -16px $brand-secondary;
            }
          }
        }
      }
    }

    &--to-delete {
      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              background-color: #f9f9f9;
            }
          }
        }
      }
    }

    &--disabled,
    &--loading {
      pointer-events: none;

      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              color: $grey-500;

              svg {
                color: $grey-500;
              }
            }
          }
        }
      }
    }

    &--expanded {
      box-shadow:
        inset 16px 0 16px -16px $brand-secondary,
        inset -16px 0 16px -16px $brand-secondary,
        inset 0 16px 16px -16px $brand-secondary;

      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              border-color: $brand-secondary;

              &:first-child {
                border-left: 1px solid $brand-secondary;
              }

              &:last-child {
                border-right: 1px solid $brand-secondary;
              }
            }
          }
        }
      }
    }

    &:not(&--details) {
      cursor: pointer;

      .table {
        &__cell {
          &.MuiTableCell {
            &-root {
              font-size: 1rem;
              overflow-wrap: anywhere;

              &:first-child {
                padding-left: 0.2rem;
              }

              &:first-child::before {
                border: 2px solid transparent;
                border-radius: 10px;
                content: '';
                height: 100%;
                margin-right: 0.5rem;
                padding-bottom: 12px;
                padding-top: 12px;
                position: relative;
                right: 0;
                top: 0;
              }
            }
          }

          &--checkbox {
            &.MuiTableCell {
              &-root {
                &:first-child::before {
                  margin-right: 0.7rem;
                }
              }
            }
          }
        }
      }
    }

    &--warning:not(&--details) {
      @include status-color-scheme($warning-light);
    }

    &--error:not(&--details) {
      @include status-color-scheme($error);
    }

    &--success:not(&--details) {
      @include status-color-scheme($success);
    }

    .row {
      &__details {
        padding: 2rem;
      }
    }
  }

  &__pagination {
    margin-top: 2rem;
  }
}
