.processed-invoices {
  .toolbar {
    &__button {
      &--show-rejected {
        margin-left: 2.29rem;
        min-width: 15rem;
      }
    }
  }
}
