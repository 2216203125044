@import '../../../styles/bootstrap';

.loading-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 10rem;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up(sm) {
    min-height: 15rem;
  }

  @include media-breakpoint-up(md) {
    min-height: 25rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 40rem;
  }

  @include media-breakpoint-up(xl) {
    min-height: 50rem;
  }
}
