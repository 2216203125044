@import './../../../../../../../../../../../../styles/variables';

.verify-invoice-data-field {
  align-items: center;
  display: flex;

  &__color-indicator,
  &__title,
  &__field {
    align-items: center;
    display: flex;
  }

  &__color-indicator {
    max-width: 1.65rem;

    .color-indicator {
      &__wrapper {
        border-radius: 4px;
        height: 3.15rem;
        width: 0.57rem;
      }
    }
  }

  &__title {
    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.35rem 0 0 0.35rem;
      }
    }
  }

  &__field {
    padding-top: 10px;
  }

  &__tooltip-content {
    .tooltip-content {
      &__value {
        color: $brand-primary;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }

  &__tooltip-button {
    cursor: help !important;
  }
}
