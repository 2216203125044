@import '../../../../styles/bootstrap';

.invoices {
  &__upload {
    margin-bottom: 1.71rem;
  }

  &__processed-pages,
  &__tabs {
    margin-bottom: 2rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 4rem;
    }
  }

  &--welcome {
    text-align: center;
  }
}
