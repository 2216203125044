@import '../../../styles/bootstrap';
@import '../../../styles/variables';

.form-container {
  margin: 4.5rem 0;
  padding: 0 4.5rem;

  @include media-breakpoint-down(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include media-breakpoint-down(xs) {
    margin: 2.5rem 0;
    padding: 0 1rem;
  }

  &--full-width {
    width: 100%;
  }
}
