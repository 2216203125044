html {
  font-size: 14px;
}

body {
  font-family:
    Roboto,
    'Helvetica Neue',
    Arial,
    -apple-system,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0 !important;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.center {
  text-align: center;
}

.row {
  &--no-gutter {
    margin-left: 0 !important;
    margin-right: 0 !important;

    > [class^='col-'],
    > [class*=' col-'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &--left {
    justify-content: left;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: right;
  }
}

.flex {
  display: flex;

  &--auto {
    flex: auto;
  }
}
