.data-hub-accordion {
  &.MuiAccordion {
    &-root {
      box-shadow: none;
    }
  }

  &.Mui {
    &-expanded {
      margin-bottom: -0.85rem;

      &:first-of-type {
        margin-top: initial;
      }
    }
  }
}
