@import '../../../../../styles/bootstrap';

.navigation-links {
  padding-top: 0;

  .MuiListItem-root {
    padding-left: 1.2rem;

    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
  }
}
