@import './../../../../styles/variables';

.data-hub-menu-item {
  &.MuiMenuItem {
    &-root {
      background-color: $white;
      border: 1px solid transparent;
      border-radius: 0.3rem;
      margin: 0.6rem 0.8rem;
      max-height: 2.86rem;
      transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: $brand-secondary;
        border-color: $brand-secondary;
        color: $black;
      }

      > *:first-child {
        margin-right: 1rem;
      }
    }
  }

  &.Mui {
    &-focused,
    &-focusVisible,
    &-focusVisible.Mui-selected,
    &-focused.Mui-selected {
      background-color: $brand-quaternary;
      border-color: $brand-quaternary;
      color: $black;
    }

    &-selected {
      background-color: transparent;
      border: 1px solid $brand-primary;
      color: $brand-primary;
    }
  }
}
