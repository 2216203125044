@import '../../../../../styles/variables';

.table-empty {
  border-bottom: 1px solid $grey-200;
  padding-bottom: 1.5rem;
  position: relative;

  &::before {
    background-image: $brand-image-secondary;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    filter: grayscale(100%);
    height: 80%;
    left: 0;
    opacity: 0.05;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__content {
    align-items: center;
    color: $resident;
    display: flex;
    height: 6rem;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 1.5rem;
    position: relative;
  }
}
