@import '../../../styles/variables';
@import '../../../styles/bootstrap';

.landing-container {
  @include media-breakpoint-up(xl) {
    min-width: 49rem;

    &__form {
      border-right: 2px solid $grey-100;
      padding-right: 3.1rem;
    }
  }

  &__external {
    padding-top: 3.1rem;

    @include media-breakpoint-up(xl) {
      padding-left: 3.1rem;
      padding-top: 0;
    }
  }

  &--single-column {
    @include media-breakpoint-up(xl) {
      max-width: 30rem;
      min-width: 30rem;
    }

    .landing-container {
      &__external {
        padding-top: 0;

        @include media-breakpoint-up(xl) {
          padding-left: 0;
        }
      }
    }
  }
}
