@import './../../../../../../../../../../../../styles/variables';

.supplier-edit-warning {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  &__icon {
    color: $warning-light;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  &__text {
    color: $grey-800;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
    text-align: center;
  }
}
