@import '../../../styles/variables';
@import '../../../styles/bootstrap';

.tabs {
  width: 100%;

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__toolbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  /* stylelint-disable no-descending-specificity */
  .MuiTab {
    &-root {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: $black;
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      text-transform: none;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      @include media-breakpoint-up(sm) {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
      }

      @include media-breakpoint-up(md) {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: $brand-tertiary;
        border-radius: 4px;
      }

      &.Mui-selected {
        color: $brand-tertiary;

        &:hover {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          color: $black;
        }
      }

      &.Mui-disabled {
        color: #c4c4c4;
      }
    }
  }

  &--sm {
    .MuiTab {
      &-root {
        font-size: 0.8rem;
      }
    }
  }

  &--md {
    .MuiTab {
      &-root {
        font-size: 1rem;
      }
    }
  }

  &--lg {
    .MuiTab {
      &-root {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &--cards {
    .MuiTab {
      &-root {
        border: 1px solid $brand-tertiary;
        border-radius: 2px;
        flex-wrap: wrap;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        padding: 1.15rem;
        text-transform: uppercase;
        width: 7.25rem;

        @include media-breakpoint-up(md) {
          margin-left: 1.2rem;
          margin-right: 1.2rem;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &.Mui-selected {
          color: $black;
        }

        &.Mui-disabled {
          border-color: $grey-100;
        }
      }
    }
  }

  &--color-secondary {
    .MuiTab {
      &-root {
        &:hover {
          background-color: $brand-secondary;
          border-radius: 4px;
        }

        &.Mui-selected {
          color: $brand-primary;

          &:hover {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            color: $black;
          }
        }
      }
    }
  }

  &--color-secondary.tabs--cards {
    .MuiTab {
      &-root {
        border: 1px solid $brand-secondary;
      }
    }
  }

  &--uppercase {
    .MuiTab {
      &-root {
        text-transform: uppercase;
      }
    }
  }

  /* stylelint-enable no-descending-specificity */
}
