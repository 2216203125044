@import './../../../styles/variables';
@import './../material/Input.mixin';

.edit-field {
  @include input();

  background-color: transparent;
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover:not(&--disabled) {
    background-color: $grey-100;
    border-color: $brand-secondary;
  }

  &--disabled {
    color: $grey-500;

    &:hover:not(.Mui-error, .Mui-disabled) {
      border-color: transparent;
      cursor: default;
    }
  }

  &__text {
    line-height: 1.5rem;
    margin-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
