@import '../../../../../../../../../../../../styles/variables';

.match-tag {
  align-items: center;
  background: $grey-100 0 0 no-repeat padding-box;
  border-radius: 4px;
  bottom: 1px;
  cursor: pointer;
  display: flex;
  height: 3.15rem;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  // TODO: find better solution
  position: relative;
  right: 1px;
  width: 13.15rem;

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    color: $grey-200;
  }
}
