@import './../../../../styles/variables';

@mixin gradient-color-scheme($primary-color, $accent) {
  $border: 1px;
  $border-left: 5px;
  $border-radius: 5px;

  background-clip: padding-box;
  border: solid $border-left transparent;
  border-radius: $border-radius;
  position: relative;

  &::before {
    background-image: linear-gradient(to bottom, $primary-color, $accent);
    border-radius: inherit;
    bottom: 0;
    content: '';
    left: 0;
    margin: -$border;
    margin-left: -$border-left;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

.data-hub-alert-gradient-box {
  &--success {
    @include gradient-color-scheme($success-dark, $success);
  }

  &--info {
    @include gradient-color-scheme($brand-info, $brand-info-secondary);
  }

  &--warning {
    @include gradient-color-scheme($warning, $warning-light);
  }

  &--error {
    @include gradient-color-scheme($error-dark, $error);
  }
}

@mixin color-scheme($primary-color, $accent) {
  &:not(.MuiAlert-filled, .MuiAlert-outlined) {
    .data-hub-alert {
      &__accent {
        background-image: linear-gradient(to bottom, $primary-color, $accent);
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        width: 0.35rem;
      }
    }
  }

  &.MuiAlert {
    &-root {
      .MuiAlert-icon {
        color: $primary-color;
      }

      &.MuiAlert {
        &-filled {
          background-image: linear-gradient(to right, $primary-color, $accent);
          color: $white;

          .MuiAlert-icon {
            color: $white;
          }
        }

        &-outlined {
          border: 0;
        }
      }
    }
  }
}

.data-hub-alert {
  overflow: auto;
  padding: 0.6rem 1.2rem;
  position: relative;

  &--success {
    @include color-scheme($success-dark, $success);
  }

  &--info {
    @include color-scheme($brand-info, $brand-info-secondary);
  }

  &--warning {
    @include color-scheme($warning, $warning-light);
  }

  &--error {
    @include color-scheme($error-dark, $error);
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      filter: brightness(96%);
      transition: all 1s ease;
    }
  }

  &__content {
    margin: 0;
  }

  &.MuiAlert {
    &-root {
      background-color: $white;
      color: $black;
      font-weight: 500;
      line-height: 1.7;
    }

    &-outlined {
      border-radius: 1px 4px 4px 1px;
    }
  }

  .MuiAlert {
    &-message {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }
}
