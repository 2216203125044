@import '../../../../styles/variables';

.data-hub-pagination {
  .MuiPagination {
    &-ul {
      .MuiPaginationItem {
        &-root {
          &.Mui-selected,
          &:hover {
            background-color: $brand-secondary;
          }
        }
      }
    }
  }
}
