@use 'sass:color';
@use './../../../../../../styles/variables' as *;

.processed-pages {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 0.6rem;

  &__progress {
    animation: fade-in 0.5s;
    background-color: $white;
    border: 0.5px solid $grey-200;
    border-radius: 8px;
    height: 0.6rem;
    max-width: 17.14rem;
    transition: opacity 0.5s ease-in;
    width: 100%;

    > span {
      background-color: $brand-secondary;
      border-radius: 8px;
    }

    &--low {
      > span {
        background-color: $warning-shadow;
      }
    }

    &--mid {
      > span {
        background-color: $warning-light;
      }
    }

    &--warning {
      > span {
        background-color: $warning;
      }
    }

    &--critical {
      border-color: $red;

      span {
        background-color: $red;
      }
    }
  }

  &__text-warning {
    align-items: center;
    background-color: color.scale($error-shadow-light, $alpha: -80%);
    border: 1px solid $error;
    border-radius: 8px;
    color: $error-dark;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 1.5rem;

    svg {
      font-size: 3.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__tooltip-content {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
