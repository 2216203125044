@import '../../../../../styles/variables';
@import '../../../../../styles/bootstrap';

.data-hub-table-pagination {
  &.MuiTablePagination {
    &-root {
      display: flex;
      font-size: 1rem;
      justify-content: center;
    }

    &-menuItem {
      border: 1px solid transparent;
      border-radius: 0.3rem;
      margin: 0.6rem 0.8rem;

      &:hover {
        background-color: $brand-secondary;
      }

      &.Mui {
        &-selected {
          background-color: $brand-secondary;

          &:hover {
            background-color: $brand-secondary;
          }
        }
      }
    }
  }

  .MuiTablePagination {
    &-selectLabel {
      margin-right: 0.5rem;
      order: 3;
    }

    &-selectLabel,
    &-displayedRows {
      display: none;
      font-size: 1rem;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &-displayedRows {
      min-width: 10rem;
    }

    &-toolbar {
      align-items: center;
      display: flex;
      padding: 0;
      width: 100%;

      .MuiInputBase-root {
        margin-right: 0;
        order: 1;

        @include media-breakpoint-up(md) {
          order: 4;
        }
      }
    }

    &-input {
      margin: 0;

      @include media-breakpoint-up(md) {
        margin-left: 0.75rem;
        margin-right: 2.5rem;
      }
    }

    &-select {
      background-color: $white;
      border: 1px solid $brand-primary;
      border-radius: 2px;
      box-shadow: 0 2px 4px #b9680029;
      opacity: 1;
      padding: 0.6rem;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: $brand-secondary;
        border-color: $brand-secondary;
      }

      &.MuiInputBase-input {
        padding-right: 4rem;
      }
    }

    &-selectIcon {
      color: $black;
      margin-right: 0.6rem;
    }

    &-actions {
      display: flex;
      flex: 1 1 100%;
      justify-content: flex-end;
      margin-left: 0;
      order: 2;

      @include media-breakpoint-up(md) {
        justify-content: center;
        padding-left: 4.5rem;
      }

      .MuiSvgIcon-root {
        font-size: 1.8rem;
      }

      .MuiButtonBase {
        &-root {
          margin-left: 0.15rem;
          margin-right: 0.15rem;

          &:not(.Mui-disabled) {
            border-radius: 8px;
            color: $black;

            &:hover {
              background-color: $brand-secondary;
            }
          }
        }
      }
    }

    &-spacer {
      flex: 1;
      order: 1;
    }
  }

  &--no-rows-label {
    .MuiTablePagination {
      &-spacer,
      &-displayedRows {
        display: none;
      }

      &-actions {
        padding-left: 0;
      }
    }
  }
}
