@import '../../../../../styles/variables';

.image-preview {
  canvas {
    height: auto !important;
    max-width: 100%;
  }

  &__preview {
    border: 1px solid $grey-200;
    cursor: move;
    position: relative;
  }

  &__toolbar {
    bottom: 6rem;
    display: flex;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: relative;

    > * {
      pointer-events: auto;
    }
  }

  &:hover {
    .image-preview__toolbar {
      opacity: 1;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &--mouse-zoom {
    .image-preview {
      &__preview {
        cursor: zoom-in;
      }
    }
  }
}
