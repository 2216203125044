@import '../../../../../styles/variables';

.notification-list {
  background-color: $white;
  border-bottom: 2px solid $brand-primary;
  height: 47.15rem;
  margin-bottom: 0;
  max-height: calc(100vh - 17.13rem);
  overflow: auto;
  padding-left: 0;

  &--empty {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 10rem;
  }
}
