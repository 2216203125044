@import './../../../../../../styles/variables';

.settings-section {
  margin-bottom: 4rem;

  &__title {
    margin-bottom: 2.3rem;

    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.35rem 0 0 0.35rem;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    min-height: 15rem;
  }

  &--main {
    margin-bottom: 3rem;

    &__title {
      font-weight: normal;
      margin-bottom: 2.2rem;
    }
  }
}
