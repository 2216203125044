@import './../../../../../../styles/variables';

.data-hub-accordion-details {
  &.MuiAccordionDetails {
    &-root {
      background: $grey-100 0 0 no-repeat padding-box;
      border-radius: 8px;
      color: $grey-400;
      opacity: 1;
      padding: 1.5rem 3rem;

      pre {
        margin-bottom: 0;
      }
    }
  }
}
