@import './../../../../styles/variables';

.MuiTooltip {
  &-tooltip {
    background-color: $white;
    border: 1px solid $grey-200;
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 $grey-100;
    color: $black;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem;
    text-align: center;
    width: auto;

    &--lg {
      max-width: 600px;
    }

    &--warning-low {
      border-color: $warning-shadow;
    }

    &--warning-mid {
      border-color: $warning-light;
    }

    &--warning {
      border-color: $warning;
    }

    &--error {
      border-color: $red;
    }
  }
}
