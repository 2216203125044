@import '../../../../../../styles/variables';
@import '../../../../../../styles/bootstrap';

.global-settings {
  &__separator {
    border: 1px solid $grey-100;
    margin-bottom: 2.4rem;
    margin-left: 1rem;
    margin-right: 1rem;

    @include media-breakpoint-up(sm) {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
  }
}
