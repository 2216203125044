@import '../../../../styles/variables';

.dashboard {
  &__header {
    font-weight: 600;
    margin-bottom: 0.75rem;
    text-align: left;
  }

  &__content {
    color: $grey-800;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &--empty {
    align-items: center;
    display: flex;
    flex-direction: column;

    .dashboard {
      &__header,
      &__content {
        text-align: center;
      }
    }
  }
}
