@import '../styles/variables';

.app {
  animation: fade-in 1s;
  display: flex;
  flex-direction: column;
  padding-left: calc(100vw - 100%);
  transition: opacity 1s ease-in;

  &__header,
  &__footer {
    flex: 0 0 auto;
  }

  &__main {
    display: flex;
    flex: auto;
    flex-direction: column;

    &--disabled {
      background-image: $disabled-content-background;
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
