@import './src/styles/variables';
@import './src/styles/bootstrap';

.landing-header {
  align-items: center;
  border-bottom: 2px solid $brand-primary;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  padding: 2.1rem;

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: left;

    .logo {
      max-width: 19rem;
    }
  }

  h1,
  h3 {
    text-align: left;
  }

  h3 {
    font-weight: 400;
  }

  h1 {
    font-weight: 300;
  }
}
