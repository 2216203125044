@import './../../../../styles/variables';
@import './../Input.mixin';

.data-hub-input {
  &-label {
    color: $black;
    margin-bottom: 0.9rem;

    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.35rem 0 0 0.35rem;
      }
    }
  }

  &.MuiInput {
    &-root {
      @include input();
    }
  }

  &.Mui-error {
    border-color: $error;
  }
}
