@import '../../../../../../../styles/variables';

.notifications-list-item {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $grey-200;
  display: flex;
  list-style: none;
  padding: 1.5rem 2.29rem 1.5rem 1.2rem;

  &__indicator {
    cursor: pointer;
    margin-right: 1.2rem;
    padding: 0.16rem;

    .badge {
      background-color: $brand-primary;
      border: 1px solid $brand-primary;
      border-radius: 2rem;
      height: 0.72rem;
      left: 0.76rem;
      top: 0.1rem;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 0.72rem;
    }

    &:hover {
      .badge {
        background-color: transparent;
        border-color: $brand-primary;
      }
    }
  }

  &--read {
    .notifications-list-item {
      &__indicator {
        cursor: auto;

        .badge {
          background-color: transparent;
          border-color: $grey-200;
        }
      }
    }
  }

  &:hover {
    background: $grey-100 0 0 no-repeat padding-box;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &__content {
    .content {
      &__message {
        margin-bottom: 0;
      }

      &__link {
        display: inline-block;
        margin-top: 0.5rem;
      }
    }
  }
}
