@import '../../../../../../../../styles/variables';

.invoice-duplicate-row {
  &__cell {
    border-color: $grey-200;
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    word-wrap: break-word;

    .cell {
      &__title {
        font-size: 1rem;
      }

      &__value {
        font-size: 1.14rem;
        margin-bottom: 0;
      }
    }
  }

  &--header {
    .invoice-duplicate-row {
      &__cell {
        border-color: $brand-primary;
        padding-bottom: 3.65rem;
        padding-top: 2rem;

        .cell {
          &__title {
            font-size: 1rem;
          }

          &__value {
            font-size: 1.43rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
