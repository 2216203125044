@import './../../../../../../../../../../../../styles/variables';

.threshold-info {
  margin-left: 1rem;

  &__text {
    background-color: $white;
    border: 1px solid $grey-200;
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 $grey-100;
    padding: 0.5rem;
  }
}
