@import '../../../styles/bootstrap';

.navigation-menu {
  display: flex;
  flex-direction: column;

  &__menu {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  &__hamburger.MuiButtonBase-root {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
