.form-modal {
  width: 100%;

  &--no-fade {
    .form-modal {
      &__buttons {
        z-index: 100;
      }
    }
  }
}
