@import './../../../../../../../../../../styles/variables';

.invoice-note {
  background: $brand-quaternary;
  border-radius: 0.57rem;
  padding: 1.14rem;

  &__content {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    gap: 1.14rem;
    line-height: 140%;

    p {
      margin-bottom: 0;
    }

    .content {
      &__timestamp {
        color: $grey-800;
        font-size: 0.9rem;
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }
}
