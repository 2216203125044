@import './../../../../../../../../styles/bootstrap';

.primary-data-sources {
  &__header {
    font-size: 1.15rem;
    font-weight: 400;
    margin-bottom: 1.7rem;
  }

  &__form {
    margin-left: 0;

    @include media-breakpoint-up(md) {
      margin-left: 4.43rem;
    }
  }
}
