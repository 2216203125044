@import './src/styles/variables';
@import './../../close-button/CloseButton.module';

$content-padding: 2.15rem;
$content-static-padding: 4.3rem;
$divider-margin-top: 1.15rem;

@mixin color-scheme($primary-color, $close, $close-hover, $accent: false, $accent-shadow: false) {
  .MuiDialogTitle-root {
    color: $black;
  }

  @if $accent {
    .MuiDialogTitle-root {
      color: $black;

      .MuiTypography-root {
        font-size: 1.72rem;
        font-weight: 600;
      }
    }

    .MuiDialog-paper {
      display: flex;
      flex-direction: row;
    }

    .data-hub-modal {
      &__accent {
        background-image: linear-gradient(to bottom, $accent, $primary-color);
        display: flex;
        height: 100%;
        position: absolute;
        width: 0.88rem;
      }
    }

    @if $accent-shadow {
      .data-hub-button {
        &.MuiButton-contained:not(&.MuiButton-loading) {
          background-color: transparent;
          border-color: $primary-color;

          &:hover {
            background-color: $accent-shadow;
          }
        }

        &.MuiButton-text:not(&.MuiButton-loading) {
          &:hover {
            background-color: $accent-shadow;
            border-color: $primary-color;
          }
        }
      }
    }
  }

  @else {
    .data-hub-modal {
      &__divider {
        background: $primary-color 0 0% no-repeat padding-box;
      }
    }
  }

  .data-hub-modal {
    &__close-button {
      @include close-button-color-scheme($close, $close-hover, $accent);
    }

    &__top-icon {
      color: $accent;
    }
  }
}

.data-hub-modal {
  border-radius: 10px;

  .MuiDialogContent-root {
    padding: 0 $content-padding $content-padding;
  }

  &--error {
    @include color-scheme($error, $error, $error-light, $error-dark, $error-shadow-light);
  }

  &--warning {
    @include color-scheme($warning-light, $warning, $brand-secondary, $warning, $warning-shadow-light);
  }

  &--success {
    @include color-scheme($success, $success, $success-light, $success-dark, $success-shadow-light);
  }

  &--error,
  &--warning,
  &--success {
    .MuiDialogContent-root {
      padding: $divider-margin-top $content-padding $content-padding;
    }
  }

  &--no-title {
    .MuiDialogContent-root {
      padding: $content-padding;
    }
  }

  &--static {
    .MuiDialogContent-root {
      padding: 0 $content-static-padding $content-padding $content-static-padding;
    }
  }

  &--static.data-hub-modal--no-title {
    .MuiDialogContent-root {
      padding: $content-static-padding $content-static-padding $content-padding $content-static-padding;
    }
  }

  &:not(&--loading, &--success, &--error, &--warning, &--static) {
    .MuiDialogContent-root {
      &::after {
        background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(255 255 255 / 100%) 90%);
        bottom: 0;
        content: '';
        height: 3.5rem;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
  }

  &--with-actions:not(&--loading, &--success, &--error, &--warning, &--static) {
    .MuiDialogContent-root {
      &::after {
        bottom: 5.79rem;
      }
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__title {
    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.5rem 0 0 0.5rem;
      }
    }
  }

  &__top-icon {
    font-size: 4rem;
    margin-bottom: 1.14rem;
  }

  .MuiDialog-paper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }

  .MuiDialogTitle-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0;
    padding-top: $content-padding;
  }

  .MuiBackdrop-root {
    backdrop-filter: blur(4px);
  }

  &__divider {
    height: 2px;
    margin-top: $divider-margin-top;
    width: 100%;
  }

  &__content {
    .content {
      &__inner {
        display: flex;
        justify-content: center;
        min-height: 6rem;

        &--md {
          min-height: 17.5rem;
        }

        &--lg {
          min-height: 25rem;
        }
      }
    }

    &--fixed {
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      padding: 0;
      width: 100%;
    }
  }

  &__actions-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 4.65rem;
    padding-left: 2.15rem;
    padding-right: 2.15rem;
    width: 100%;
  }

  &--loading {
    .data-hub-modal {
      &__content {
        &--md {
          min-height: 25rem;
        }

        &--lg {
          min-height: 32.5rem;
        }
      }
    }
  }

  &--full {
    @include color-scheme(transparent linear-gradient(180deg, $info-gradient-left 0%, $info-gradient-right 100%), $brand-primary, $brand-secondary);

    .data-hub-modal__content {
      &:not(&--fixed) {
        padding: $content-padding;
      }

      &--fixed.MuiDialogContent-root {
        padding: 0;
      }
    }
  }
}
