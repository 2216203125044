@import './../../../../styles/variables';
@import './../Input.mixin';

.data-hub-text-field {
  &-label {
    color: $black;
    margin-bottom: 0.9rem;

    &--required {
      &::after {
        color: $error;
        content: '*';
        margin: -0.35rem 0 0 0.35rem;
      }
    }
  }

  .MuiFormControl {
    &-root {
      margin: 0;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput {
    &-root {
      @include input();

      padding-right: 1.2rem;

      &.Mui-error {
        border-color: $error;
      }
    }
  }

  .MuiOutlinedInput {
    &-notchedOutline {
      border: 0;
    }
  }
}
