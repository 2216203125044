.table-container {
  min-height: 15rem;
}

.table {
  table-layout: fixed;
}

.cell {
  border: 0;

  &--row {
    word-wrap: break-word;
  }

  &--name {
    width: 30%;
  }

  &--status {
    width: 20%;
  }
}

.progress {
  display: flex;
  justify-content: center;

  &__bar {
    border-radius: 8px;
    height: 0.6rem;
    width: 70%;
  }
}

.buttons {
  display: flex;
  justify-content: center;
}
