@import '../../../../../../../../styles/bootstrap';

.verification-modal {
  .MuiDialog {
    &-paperWidthXl {
      max-width: 125.5rem;

      @include media-breakpoint-up(lg) {
        min-height: 25rem;
      }

      @include media-breakpoint-up(xxxl) {
        min-height: 50rem;
      }
    }
  }
}
