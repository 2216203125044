@import './../../../../styles/variables';

@mixin chip-color-scheme($color, $font-color) {
  border-color: $color;

  .MuiChip {
    &-icon {
      margin-left: 0.6rem;
    }
  }

  &.MuiChip {
    &-filled {
      background-color: $color;
      color: $font-color;

      .MuiChip {
        &-icon {
          color: $font-color;
        }
      }
    }

    &-outlined {
      color: $color;

      .MuiChip {
        &-icon {
          color: $color;
        }
      }
    }
  }
}

.data-hub-chip {
  @include chip-color-scheme($grey-200, $black);

  &--primary {
    @include chip-color-scheme($brand-primary, $black);

    &.MuiChip {
      &-outlined {
        color: $black;
      }
    }
  }

  &--secondary {
    @include chip-color-scheme($brand-secondary, $black);

    &.MuiChip {
      &-outlined {
        color: $black;
      }
    }
  }

  &--info {
    @include chip-color-scheme($brand-info, $white);
  }

  &--success {
    @include chip-color-scheme($success-dark, $white);
  }

  &--error {
    @include chip-color-scheme($error, $black);
  }

  &--warning {
    @include chip-color-scheme($warning-light, $black);
  }

  &--warning-secondary {
    @include chip-color-scheme($warning-secondary, $black);
  }

  &--warning-tertiary {
    @include chip-color-scheme($warning-tertiary, $black);
  }

  &--black {
    @include chip-color-scheme($black, $white);
  }

  &--transparent {
    @include chip-color-scheme(transparent, $black);

    &.MuiChip {
      &-outlined {
        color: $black;
      }
    }
  }

  &--uppercase {
    &.MuiChip {
      &-outlined,
      &-filled {
        text-transform: uppercase;
      }
    }
  }

  &.MuiChip {
    &-outlined {
      background-color: transparent;
    }
  }
}
