@import '../../../../../styles/variables';
@import '../../../close-button/CloseButton.module';

.notifications-header {
  &__content {
    padding: 1.3rem 3.43rem;
  }

  &__title-container {
    align-items: center;
    display: flex;

    .title-container {
      &__title {
        margin-bottom: 0;
        margin-right: 1rem;
      }

      &__summary {
        color: $grey-400;
      }
    }
  }

  &__separator {
    background-color: $white;
    height: 1.27rem;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  &__close-button {
    @include close-button-color-scheme(linear-gradient(180deg, $info-gradient-left 0%, $info-gradient-right 100%), $brand-primary, $brand-secondary);
  }
}
