@import '~bootstrap/scss/functions';

$h1-font-size: 6.9rem;
$h2-font-size: 4.8rem;
$h3-font-size: 3.4rem;
$h4-font-size: 2.4rem;
$h5-font-size: 2rem;
$h6-font-size: 1.4rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px,
  xxxxl: 2400px,
  xxxxxl: 3400px,
);

$container-max-widths: (
  sm: 540px,
  md: 770px,
  lg: 1010px,
  xl: 1280px,
  xxl: 1480px,
  xxxl: 1520px,
  xxxxl: 1580px,
  xxxxxl: 1680px,
);

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/vendor/rfs';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/reboot';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-table {
      display: table !important;
    }
    .d#{$infix}-table-row {
      display: table-row !important;
    }
    .d#{$infix}-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
}
