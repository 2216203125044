$tabs-height: 2.92rem;

.notifications-content {
  &__tabs {
    .MuiTabs {
      &-root {
        min-height: $tabs-height;
      }
    }

    .MuiTab {
      &-root {
        margin-bottom: 0.2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        min-height: $tabs-height;
        padding: 0.5rem;
      }
    }
  }
}
