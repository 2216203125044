@import '../../../../../../styles/bootstrap';

.invoice-duplicates {
  &__table {
    table-layout: fixed;

    .table {
      &__separator {
        font-size: 1.43rem;
        font-weight: 500;
        padding: 2.57rem 1rem;
        text-align: center;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .buttons {
      &__button {
        margin: 1rem 0;

        @include media-breakpoint-up(sm) {
          margin: 0 2rem;
        }
      }
    }
  }
}
