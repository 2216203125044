@import 'variables';
@import 'typography';
@import 'general';
@import 'bootstrap';

html {
  a {
    &,
    &:link {
      color: $brand-primary-dark;
    }

    &:visited {
      color: $brand-info-secondary;
    }

    &:focus,
    &:hover,
    &:active {
      color: $brand-primary;
    }
  }
}
