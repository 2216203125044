@import '../../../styles/variables';
@import '../../../styles/bootstrap';

.no-permissions {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__icon {
    color: $warning;
    font-size: 4rem;
    margin-bottom: 1.14rem;
  }

  &__header {
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  &__content {
    color: $grey-800;
    line-height: 1.5;
    margin-bottom: 3rem;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__header,
  &__content {
    text-align: center;
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;

    .buttons-container {
      &__button {
        @include media-breakpoint-up(sm) {
          max-width: 20rem !important;
          min-width: 15rem !important;
        }

        &:not(:last-child) {
          margin-bottom: 1.4rem;
        }
      }
    }
  }
}
