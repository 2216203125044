@import '../../../../../styles/variables';

.notification-bell {
  border-radius: 0.5rem;

  &__icon-button {
    width: 3.21rem;
  }

  &__badge {
    background-color: $red;
    border: 2px solid $black;
    border-radius: 1rem;
    height: 1rem;
    left: 1.06rem;
    pointer-events: none;
    top: 1.06rem;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 1rem;
  }

  &:hover {
    background-color: $brand-primary;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .notification-bell__badge {
      border-color: $brand-primary;
    }
  }
}
