@import '../../../../../styles/variables';

.zoom {
  background-color: $white;
  border: 1px solid $grey-200;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 $grey-100;
  display: inline-flex;
  padding: 0.7rem;

  &__button.data-hub-icon-button {
    margin-right: 0.4rem;
    padding: 0.6rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
