.in-progress-invoices {
  .toolbar {
    &__button {
      width: 11rem;

      &--sync {
        margin-left: 2.29rem;
      }
    }
  }
}
