@import '../../../../../../../../styles/variables';

.user-permission {
  &__table {
    margin-bottom: 1.6rem;

    .table {
      &__name {
        p {
          margin-bottom: 0;

          &:nth-child(2) {
            color: $grey-500;
          }
        }
      }
    }
  }
}
