.data-hub-autocomplete-select {
  .MuiAutocomplete {
    &-clearIndicator {
      margin-right: 0.4rem;
    }
  }

  .data-hub-text-field {
    .MuiInputBase-root.MuiOutlinedInput {
      &-root {
        padding: 0;
      }
    }

    .MuiAutocomplete-input.MuiInputBase-input.MuiOutlinedInput {
      &-input {
        padding: 0.9rem;
      }
    }
  }

  .MuiCircularProgress {
    &-root {
      pointer-events: none;
      position: relative;
      right: 3rem;
    }
  }

  &__option {
    &--accent {
      /* TODO: consider styling for accent item */
      //font-weight: 500;
    }
  }
}

.data-hub-autocomplete-list-box {
  .MuiAutocomplete {
    &-listbox {
      height: auto !important;
      max-height: 250px;
      padding-bottom: 0;
      padding-right: 1.6rem;
      padding-top: 0.3rem;
      width: auto !important;

      ul {
        position: relative;
      }
    }
  }
}
