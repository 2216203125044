// # Colors
// ## Brand
$brand-primary: #f2aa1e;
$brand-primary-dark: #b96800;
$brand-primary-shadow: #b9680029;
$brand-primary-shadow-light: #b9680059;
$brand-secondary: #fcd57a;
$brand-tertiary: #ffc748;
$brand-tertiary: #ffc748;
$brand-quaternary: #fae6b2;
$brand-logo-background: #000;
$brand-info: #7e29b6;
$brand-info-secondary: #f67e7d;

// ## Grey
$grey-800: #717171;
$grey-500: #979797;
$grey-550: #9b9b9b;
$grey-400: #898989;
$grey-300: #bbb;
$grey-200: #c4c4c4;
$grey-100: #f6f6f6;
$grey-shadow: #88888826;

// ## Common
$white: #fff;
$black: #000;
$red: #f20606;

// ## Messages
$info-gradient-left: #ffc748;
$info-gradient-right: #f29606;
$success: #00b00c;
$success-dark: #008209;
$success-light: #00ca0c;
$success-shadow-light: #0083091a;
$success-lighter: #a2b8b6;
$warning: #b96800;
$warning-light: #f29606;
$warning-shadow: #f2aa1e;
$warning-shadow-light: #fcd57a;
$warning-secondary: #f70;
$warning-tertiary: #f67e7d;
$error: #f00;
$error-dark: #c00202;
$error-light: #ff4747;
$error-shadow-light: #c0020233;

// ## Social
$fb: #3b5998;
$fb-dark: #2d4880;
$google: #db2828;
$google-dark: #a81e1e;
$resident: #3b3b3b;
$resident-dark: #282828;
