@import '../../../../../styles/variables';

.user-profile-menu {
  color: $brand-primary;

  &__image {
    border-radius: 2rem;
    margin-right: 0.7rem;
    width: 2rem;
  }

  &__menu {
    .MuiPaper-root {
      width: 14rem;
    }

    .menu {
      &__divider.MuiDivider-root {
        border: 1px solid $grey-100;
        margin: 0.8rem auto;
        opacity: 1;
        width: 80%;
      }
    }
  }
}
