@import './src/styles/variables';

@mixin close-button-color-scheme($close, $close-hover, $accent: false) {
  @if $accent {
    .close-button {
      &__wrapper {
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $brand-primary;

          .close-button__icon {
            color: $brand-primary;
          }
        }
      }
    }
  }

  @else {
    .close-button {
      &__wrapper {
        background-color: $close;

        &:hover {
          background-color: $close-hover;
        }
      }
    }
  }
}

.close-button {
  color: $black;
  height: 10.8rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 10.8rem;

  &__wrapper {
    border: 1px solid transparent;
    border-radius: 8rem;
    bottom: 5rem;
    content: '';
    cursor: pointer;
    height: 35.7rem;
    left: 4.7rem;
    position: absolute;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 35.7rem;
    z-index: 20;
  }

  &__icon {
    bottom: 2.8rem;
    color: $black;
    left: 3.2rem;
    position: absolute;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
