@import '../../../../../styles/variables';

.drawer-menu {
  &__header {
    align-items: center;
    display: flex;
    height: 6.8rem;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
