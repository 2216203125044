@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('./../assets/fonts/Roboto-Light.ttf') format('truetype'),;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('./../assets/fonts/Roboto-Regular.ttf') format('truetype'),;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('./../assets/fonts/Roboto-Medium.ttf') format('truetype'),;
}
