@import '../../../../styles/variables';

.data-hub-date-picker {
  color: initial;
}

.MuiCalendarPicker {
  &-root {
    .MuiIconButton {
      &-root {
        border-radius: 4px;

        &.Mui-selected,
        &:hover {
          background-color: $brand-secondary;
        }
      }
    }

    .MuiPickersDay {
      &-root {
        border-radius: 4px;

        &.Mui-selected,
        &:hover {
          background-color: $brand-secondary;
        }
      }
    }

    .PrivatePickersSlideTransition {
      &-root {
        min-height: 15rem;
      }
    }
  }
}
