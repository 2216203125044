.container {
  display: flex;
  justify-content: center;
  min-height: 15rem;

  .root {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .current-logo {
      margin-bottom: 1rem;
      max-width: 25rem;
    }

    &__container {
      text-align: center;
    }
  }
}
