.step-navigation-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &__button {
    display: flex;
    justify-content: center;

    &--first {
      justify-content: flex-start;
    }

    &--last {
      justify-content: flex-end;
    }
  }
}
