@import './../../../../styles/variables';

@mixin social-button($color, $accent-color) {
  &.MuiButton {
    &-contained {
      background-color: $color;
      color: $white;
      height: 2.9rem;
      text-transform: none;

      &:hover {
        background-color: $accent-color;
      }
    }

    &-loading {
      background-color: $grey-100;
      border-color: $grey-200;
      box-shadow: none;
      color: $grey-100;
    }

    &-disabled {
      background-color: rgb(0 0 0 / 12%);
      border-color: transparent;
      box-shadow: none;
      color: rgb(0 0 0 / 26%);
    }
  }
}

.data-hub-button {
  &.MuiButton {
    &-root {
      background-color: $brand-primary;
      border: 1px solid transparent;
      border-radius: 2px;
      box-shadow: 0 2px 4px #b9680029;
      color: $black;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.01rem;
      min-width: 5rem;
      opacity: 1;
      padding: 0.5rem 0.9rem;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        box-shadow: 0 2px 4px #b9680029;
      }
    }

    &-contained {
      &:hover {
        background-color: $brand-secondary;
        border-color: transparent;
      }
    }

    &-text {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;

      &.MuiButton-disabled {
        background-color: transparent;
      }

      &:hover {
        background-color: $brand-secondary;
      }
    }

    &-outlined {
      background-color: $white;
      border: 1px solid #f2aa1e;

      &:hover {
        background-color: $brand-secondary;
        border-color: transparent;
      }
    }

    &-link {
      &,
      &:hover {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        display: contents;
        min-width: auto;
        padding: 0;
        text-transform: none;
      }

      &:hover {
        color: $brand-primary;
        text-decoration: underline;
      }
    }

    &-toggle {
      &,
      &:hover {
        background-color: $white;
        border: 1px solid transparent;
        box-shadow: none;
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 1.2;
        min-width: auto;
        padding: 0.3rem 0.8rem;
        text-transform: lowercase;
      }

      &:hover {
        border-color: $brand-secondary;
      }
    }

    &-sizeSmall {
      font-size: 0.85rem;
      font-weight: 500;
      letter-spacing: 0.01rem;
      min-width: 4rem;
      padding: 0.25rem 0.6rem;
    }

    &-colorText {
      color: $brand-primary;

      &:hover {
        color: $black;
      }
    }

    &-menu {
      background-color: transparent;
      box-shadow: none;
      color: $brand-primary;
      font-weight: 500;
      text-transform: none;

      &:hover {
        background-color: $brand-primary;
        color: $black;
      }
    }

    &-loading {
      background-color: $grey-100;
      border-color: $grey-200;
      box-shadow: none;
      color: $grey-100;
    }

    &-disabled {
      background-color: rgb(0 0 0 / 12%);
      border-color: transparent;
      box-shadow: none;
      color: rgb(0 0 0 / 26%);
    }
  }

  &--fb {
    @include social-button($fb, $fb-dark);
  }

  &--google {
    @include social-button($google, $google-dark);
  }

  &--resident {
    @include social-button($resident, $resident-dark);

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 0;

      img {
        height: 1.2rem;
      }
    }
  }
}
