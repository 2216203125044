@import './../../../../styles/variables';

.data-hub-form-control {
  &.MuiFormControl {
    &-root {
      span {
        &[role='alert'] {
          color: $error;
          padding-top: 0.5rem;
          white-space: nowrap;
        }
      }

      .MuiFormControlLabel-label {
        line-height: 1;
      }
    }
  }
}
