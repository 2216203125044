.invoice-note-form-section {
  margin: 0.57rem 0 0;

  &__form {
    padding-bottom: 2.15rem;

    &--message {
      padding-bottom: 0.65rem;
    }
  }
}
