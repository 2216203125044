@import './../../../../../styles/variables';

.table-header {
  background-color: $grey-100;

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__cell {
    .cell {
      &__sort-label {
        align-items: center;
        display: flex;
      }
    }

    &.MuiTableCell {
      &-root {
        font-size: 1rem;
        font-weight: 500;

        &:first-child {
          padding-left: 1.21rem;
        }
      }
    }

    &--sortable.table-header__cell--center {
      &.MuiTableCell {
        &-root {
          span {
            padding-left: 1.29rem;
          }
        }
      }
    }
  }
}
