@import './../../../../styles/variables';

.data-hub-switch {
  .MuiSwitch {
    &-thumb {
      border: 1px solid $brand-primary;
      box-shadow: inset -1px -2px 2px $brand-primary-shadow, 1px 1px 3px $brand-primary-shadow-light;
      color: $grey-100;
      opacity: 1;
    }

    &-track {
      border-radius: 15px;
      bottom: 3px;
      opacity: 0.05;
      padding: 10px;
      position: relative;
    }
  }

  .Mui-checked + .MuiSwitch-track {
    opacity: 1;
  }
}
