@import '../../../../../../../../../../../../../styles/variables';

.match-section-paginator {
  align-items: center;
  display: flex;
  justify-items: center;

  &__previous-button {
    padding-right: 0.7rem !important;

    svg {
      margin-left: 0.3rem;
    }
  }

  &__pages {
    margin: 0 0.7rem;
  }

  &--error {
    .match-section-paginator {
      &__pages,
      &__previous-button,
      &__next-button {
        color: $error;
      }

      &__previous-button,
      &__next-button {
        &:hover {
          color: $black;
        }
      }
    }
  }
}
