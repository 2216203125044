.upload-hub-welcome {
  text-align: center;

  &__logo {
    margin-bottom: 3rem;
    margin-top: 1rem;
    width: 10rem;
  }

  &__header {
    margin-bottom: 1.5rem;
  }

  &__text {
    line-height: 1.6rem;
    margin-bottom: 3rem;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
