@import './../../../styles/variables';

@mixin input() {
  background-color: $grey-100;
  border: 1px solid $grey-100;
  border-radius: 4px;
  margin-top: 0;
  opacity: 1;
  padding: 0.9rem;

  input {
    padding: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield;
    }
  }

  &:hover:not(.Mui-error, .Mui-disabled) {
    border-color: $brand-secondary;
  }

  &::before,
  &::after {
    display: none;
  }

  .MuiInputBase {
    &-input {
      &:-webkit-autofill {
        border-color: $grey-100;
        box-shadow: inset 0 0 0 100px $grey-100;
      }
    }
  }

  &.Mui-focused:not(.Mui-error) {
    background-color: $white;
    border-color: $brand-secondary;

    .MuiInputBase {
      &-input {
        &:focus {
          border-color: $white !important;
          box-shadow: inset 0 0 0 100px $white !important;
        }
      }
    }
  }
}
