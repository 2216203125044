@import './../../../../../styles/variables';

.table-toolbar {
  background-color: $grey-100;
  padding: 0;
  position: absolute;

  &__checkbox-cell {
    padding-left: 1.21rem;
  }

  &__actions-cell {
    padding: 0;

    .actions-cell {
      &__actions {
        display: flex;
        margin-left: 1.2rem;
        padding: 0;

        .actions {
          &__action {
            border: 1px solid $brand-primary;
            box-shadow: 0 2px 4px rgb(185 104 0 / 16.1%);
            margin: 0;
          }
        }
      }
    }
  }

  &__details-box {
    padding-right: 2.6rem;
    text-align: right;
  }
}
