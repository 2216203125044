@import '../../../../../../styles/bootstrap';

.invoice-receiver {
  padding-left: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.15rem;

    .button-container {
      &__button {
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: initial;
        }
      }
    }
  }
}
