@import '../../../../../styles/variables';

.pdf-preview {
  .react-pdf {
    &__Document {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__Page {
      border: 1px solid $grey-200;
      cursor: move;
      max-width: 100%;

      canvas {
        height: auto !important;
        max-width: 100%;
      }

      &__textContent {
        height: 100% !important;
        max-width: calc(100% - 2em) !important;
        overflow: hidden !important;
        width: 100% !important;
      }
    }

    &__message {
      color: $black;
      padding: 20px;
    }
  }

  &__toolbar {
    bottom: 6rem;
    display: flex;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: relative;

    > * {
      pointer-events: auto;
    }

    .toolbar {
      &__paginator {
        display: flex;
        justify-content: center;
        margin-right: 2.5rem;

        .MuiPagination {
          &-ul {
            background-color: $white;
            border: 1px solid $grey-200;
            border-radius: 4px;
            box-shadow: 0 2px 16px 0 $grey-100;
            padding: 0.7rem;
            transition: opacity ease-in-out 0.2s;
          }
        }
      }
    }
  }

  &:hover {
    .pdf-preview__toolbar {
      opacity: 1;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &--mouse-zoom {
    .react-pdf {
      &__Page {
        cursor: zoom-in;
      }
    }
  }
}
