@import '../../../styles/variables';
@import '../../../styles/bootstrap';

.header {
  background-color: $black;
  border-bottom: solid 1px $black;
  box-shadow: 0 5px 16px rgb(0 0 0 / 25%);
  color: $brand-primary;
  height: 6.8rem;
  left: 0;
  padding-left: calc(100vw - 100%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &__container {
    align-items: center;
    display: flex;
    height: 6.8rem;
    justify-content: space-between;

    .actions__container {
      display: flex;
    }

    .container {
      &__logo {
        width: 7.5rem;
      }

      &__user-profile {
        display: none;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

      &__menu {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }

      &__notifications {
        margin-left: 1rem;
      }
    }
  }
}
