.data-hub-accordion-summary {
  &.MuiAccordionSummary {
    &-root {
      cursor: auto !important;
    }
  }

  .MuiAccordionSummary {
    &-content {
      cursor: auto !important;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
