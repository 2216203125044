@import '../../../styles/variables';

.data-hub-tabs {
  align-items: center;
  width: 100%;

  /* stylelint-disable no-descending-specificity */
  .MuiTabs {
    &-indicator {
      background-color: $brand-tertiary;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &-scrollButtons {
      border-radius: 0.5rem;
      color: $black;
      height: 2.85rem;
      padding: 12px;
      width: 2.85rem;

      &:hover {
        background-color: $brand-tertiary;
      }

      &:first-child {
        margin-right: 0.6rem;
      }

      &:last-child {
        margin-left: 0.6rem;
      }
    }
  }

  &--cards {
    .MuiTabs {
      &-indicator {
        display: none;
      }
    }
  }

  &--color-secondary {
    .MuiTabs {
      &-indicator {
        background-color: $brand-secondary;
      }

      &-scrollButtons {
        &:hover {
          background-color: $brand-secondary;
        }
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}
