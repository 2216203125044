@import './../../../../styles/variables';

.data-hub-icon-button {
  &.data-hub-icon-button--outlined {
    &.MuiIconButton {
      &-root {
        border: 1px solid $brand-secondary;
        border-radius: 0.5rem;
        color: $black;
        padding: 0.3rem;

        &:hover {
          background-color: $brand-secondary;
        }
      }
    }
  }

  &.data-hub-icon-button--input-group {
    &.MuiIconButton {
      &-root {
        background-color: $grey-100;
        border: 1px solid $grey-100;
        border-radius: 0.5rem;
        color: $black;

        &:hover {
          background-color: $brand-secondary;
          border-color: $brand-secondary;
        }
      }
    }
  }

  &.Mui {
    &-disabled {
      svg {
        color: $grey-200;
      }
    }
  }

  &.MuiIconButton {
    &-root {
      border-radius: 0.5rem;
      color: $black;
      padding: 0.86rem;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: $brand-secondary;
      }
    }

    &-colorSecondary {
      color: $brand-primary;

      &:hover {
        background-color: $brand-primary;
        color: $black;
      }
    }

    &-sizeSmall {
      svg {
        font-size: 1.15rem;
      }
    }
  }
}
