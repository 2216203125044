.back-button {
  &__icon {
    margin-right: 1.2rem;
  }

  &__label {
    font-weight: 400;
    text-transform: none;
  }
}
