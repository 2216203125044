@import '../../../../styles/bootstrap';

.external-login {
  &__header {
    display: none;
    font-size: 1rem;
    margin-bottom: 2.2rem;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &--sign-up {
    .external-login {
      &__header {
        display: block;
        text-align: center;
      }
    }
  }
}
